export const DEALERSHIP_NAME = "Hellcat Motors"
export const LOGO_PATH = "/images/logo.png"
export const FAVICON_PATH = "/images/favicon.png"
export const TAGLINE = "Great Vehicles, Low Prices, Exceptional Service."
export const ADDRESS = "1955 Cleveland Ave, Suite 200 | Santa Rosa, CA 95401 "
export const PHONE_NUMBER = "(707) 387-6463"
export const EMAIL = "sales@hellcatmotorsllc.net"
export const COLOR_SCHEME = "blue"

export const BUSINESS_HOURS = [
  { day: "Monday-Wednesday", hours: "1:00PM - 4:00PM" },
]

export const USE_LOGO = 1
export const WANTS_TOP_BAR = true 
export const WANTS_FOOTER = true 

export const WANTS_FOOTER_SOCIAL = true
export const WANTS_FACEBOOK = true
export const WANTS_INSTAGRAM = true
export const WANTS_TWITTER = true

export const HAS_CARFAX = false 
export const HAS_VEHICLE_PAYMENTS = false

export const WANTS_CARD_FOOTER = true
export const WANTS_CARD_TOP_LABELS = true
export const WANTS_FAVORITES = true
export const WANTS_SOCIAL_PROOF = false

export const WANTS_FINANCING = false
export const WANTS_FINANCING_SECTION = false
export const FINANCING_TITLE = "Contact Us"
export const FINANCING_SUBTITLE = "We'd love to hear from you."
export const FIN_CONTACT_TITLE = "Visit Our Showroom"

export const WANTS_INVENTORY_SCROLL = true
export const INVENTORY_SCROLL_NUMBER = "6"
export const WANTS_BROWSE_BY_TYPE = true
export const WANTS_SPECIALTY_TYPES = true

export const WANTS_TRADE_SECTION = true
export const WANTS_SERVICE_SECTION = false

export const WANTS_TESTIMONIAL_SCROLL = true
export const TESTIMONIAL_SECTION_TITLE = "Reviews from Our Customers!"
export const HAS_GOOGLE_REVIEWS = false
export const HAS_YELP_REVIEWS = false
export const HAS_CUSTOM_REVIEWS = true

export const MAP_URL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3124.3922047214933!2d-122.72931042394504!3d38.45550577265652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80843872fd0f928b%3A0x7e9a430a61c698d9!2s1955%20Cleveland%20Ave%20%23200%2C%20Santa%20Rosa%2C%20CA%2095401!5e0!3m2!1sen!2sus!4v1716564087767!5m2!1sen!2sus"
export const MAP_LINK_URL = "https://www.google.com/maps/place/1955+Cleveland+Ave+%23200,+Santa+Rosa,+CA+95401/@38.4555058,-122.7293104,17z/data=!3m1!4b1!4m6!3m5!1s0x80843872fd0f928b:0x7e9a430a61c698d9!8m2!3d38.4555016!4d-122.7267355!16s%2Fg%2F11lkj6v1b6?entry=ttu"

export const CUSTOM_REVIEWS = [
  {
    name: "Edward Johnson",
    title: "Happy Buyer",
    testimonial:
      "The dealership was able to answer all my questions and provide me with all the information I needed. Their level of service and commitment is commendable.",
    rating: 4,
  },
  {
    name: "Fiona White",
    title: "First-time Buyer",
    testimonial:
      "Their selection of cars is excellent, and the staff was very patient and knowledgeable. I never felt rushed or pushed into making a decision.",
    rating: 4,
  },
  {
    name: "George Thompson",
    title: "Long-time Customer",
    testimonial:
      "This dealership always provides top-notch service. Their staff is efficient and friendly, and they have always been able to meet my needs and exceed my expectations.",
    rating: 5,
  },
  {
    name: "Helen Martin",
    title: "Returning Customer",
    testimonial:
      "The finance team at this dealership is incredible. They helped me secure a fantastic financing deal and made sure I understood all the terms and conditions.",
    rating: 5,
  },
]
export const GOOGLE_REVIEWS = [
  
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
]

export const YELP_REVIEWS = [
  {
    name: "John Darnold",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very helpful, and the staff were friendly and knowledgeable. I got a great deal on my new car, and the process was seamless and quick.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
]

export const VEHICLE_CARD_LOW_PRICE = "Low Price"
export const VEHICLE_CARD_LOW_MILES = "Low Miles"
export const VEHICLE_CARD_INSPECTED_TEXT = "Dealer Inspected"
export const VEHICLE_CARD_PRIMARY_FOOTER_TEXT = "Local Delivery"
export const VEHICLE_CARD_SECONDARY_FOOTER_TEXT = "Get it Tomorrow"

export const DISCLAIMER = `The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude government fees and taxes, finance charges, dealer document preparation charges and emission testing charges.
Please contact us for more information on
financing options and terms.
`

export const CONTACT_FORM_TITLE = "Contact Us"
export const CONTACT_FORM_SUBTITLE = "We'd love to hear from you."
export const CON_CONTACT_TITLE = "Visit Our Showroom"
